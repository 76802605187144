<template>
    <div id="surveyCreator" />
</template>

  <script>
import { SurveyCreator } from "survey-creator-knockout";


import "survey-core/survey.i18n.js";
import "survey-creator-core/survey-creator-core.i18n.js";
import "survey-core/defaultV2.css";
import "survey-creator-core/survey-creator-core.css";


const creatorOptions = {
  showLogicTab: true,
  isAutoSave: true,
  showJsonEditorTab: false,
  haveCommercialLicense: true,
};

const filesRoot = process.env.VUE_APP_FILES_ROOT;

export default {
  name: "survey-creator",
  data() {
    return {};
  },


  async mounted() {
    await this.$store.dispatch(
      "loadSurveyPayload",
      this.$route.params.surveyId
    );

    let creator = new SurveyCreator(creatorOptions);
    // creator.showEmbeddedSurveyTab = true;

    let surveyContent = await this.fetchSurveyPayload(
      filesRoot + this.$store.getters.getSurveyPayload
    );

    // console.log("The creator text", surveyContent);

    creator.text = surveyContent;

    creator.saveSurveyFunc = (saveNo, callback) => {
      this.updateSurveyJson(creator.JSON);

      callback(saveNo, true);
    };

    creator.render("surveyCreator");
  },

  methods: {
    async fetchSurveyPayload(url) {
      let response = await fetch(url);
      let data = response.text();

      return data;
    },
    updateSurveyJson(payload) {
      var updatedSurvey = {
        surveyUniqueId: this.$route.params.surveyId,
        surveyPayload: JSON.stringify(payload),
      };
      this.$store.dispatch("updateSurveyPayload", updatedSurvey);
    },
  },
};
</script>
<style scoped>
#surveyCreator {
  height: 120vh;
  width: 100%;
}
.background {
  /* background-image: url("../../assets/images/background1.png"); */
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
